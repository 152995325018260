import { Notification } from "element-ui";

const position = "top-right";

export const notifications = {
  success: ({ title, message }) => {
    Notification.success({
      title: title || "Yeah!",
      message: message || "Proceso realizado exitosamente",
      position
    });
  },
  error: ({ title, message }) => {
    Notification.error({
      title: title || "Ups!",
      message: message || "Ocurrio un error inesperado",
      position
    });
  },
  warning: ({ title, message }) => {
    Notification.warning({
      title: title || "Atención",
      message: message || "",
      position
    });
  }
};
