const documentFilters = {
  keyRangeDates: "",
  from: "",
  to: "",
  status: "",
  search: "",
  orderBy: "",
  client: ""
};

export default {
  state: {
    modules: {
      catalog: {
        product: {
          add: {
            name: "",
            description: "",
            code: "",
            amount: "",
            is_stock_counter: false,
            image: ""
          }
        }
      },
      sales: {
        documents: {
          quotes: {
            filters: {
              get: documentFilters
            }
          },
          invoices: {
            filters: {
              get: {
                ...documentFilters,
                ...{
                  is_paid: null
                }
              }
            }
          },
          creditNotes: {
            filters: {
              get: documentFilters
            }
          },
          debitNotes: {
            filters: {
              get: documentFilters
            }
          },
          withholdings: {
            filters: {
              get: documentFilters
            }
          },
          payments: {
            filters: {
              get: documentFilters
            }
          },
          liquidations: {
            filters: {
              get: documentFilters
            }
          }
        }
      }
    },
    notification: false
  },
  mutations: {
    setSalesDocumentsFiltersGet: (state, { documentKey, data }) => {
      state.modules.sales.documents[documentKey].filters.get = data;
    },
    updateNotification(state, value) {
      state.notification = value;
    }
  },
  actions: {
    salesDocumentsFiltersGet({ state, commit }, { documentKey, filters }) {
      const getFilters = state.modules.sales.documents[documentKey].filters.get;

      commit("setSalesDocumentsFiltersGet", {
        documentKey,
        data: {
          ...getFilters,
          ...filters
        }
      });
    },

    sendNotification({commit}, value){
      commit("updateNotification", value);
    }
  },
  getters: {
    getModule: state => module => {
      return state.modules[module];
    },
    getNotification: state => state.notification
  }
};
