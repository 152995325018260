import businessService from "../services/index";

export default {
  actions: {
    storeBusiness({ dispatch }, data) {
      dispatch("toggleRequestLoading");
      return new Promise((resolve, reject) => {
        businessService
          .createBusiness(data)
          .then(response => {
            const { success, data } = response.data;
            if (success) {
              dispatch("userUpdate", data);
              resolve(response);
            }
            reject(false);
          })
          .catch(error => reject(error))
          .finally(() => dispatch("toggleRequestLoading"));
      });
    }
  }
};
