import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/";

/*
 * @@ Module router imports
 */

import AuthRouters from "../modules/auth/router";
import PublicRouters from "../modules/public/router";
import DashboardRouters from "../modules/dashboard/router";
import CheckoutRouters from "../modules/checkout/router";

Vue.use(VueRouter);

const routes = [];

const router = new VueRouter({
  mode: "history",
  routes: [
    ...routes,
    ...PublicRouters,
    ...AuthRouters,
    ...DashboardRouters,
    ...CheckoutRouters,
    ...[
      {
        path: "*",
        name: "404",
        component: () => import("../modules/404/NotFound")
      }
    ]
  ]
});

router.beforeEach((to, from, next) => {
  const isLogged = store.getters.isLogged;
  if (to.name === "home") next("/login");
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (isLogged) {
      next();
      return;
    }
    next("/login");
  } else {
    if (to.name === "login" || to.name === "register") {
      if (isLogged) router.push({ name: "dashboard" });
    }
    next();
  }
});
export default router;
