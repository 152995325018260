import Vue from "vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";

// import "jquery/dist/jquery.min.js"
// import "bootstrap/dist/js/bootstrap.min.js"

/*
 * Import Element
 */
import Element from "element-ui";
import locale from "element-ui/lib/locale/lang/es";
import "./theme/element-variables.scss";

import "./theme/main.scss";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import moment from "moment-timezone";
import InfiniteLoading from "vue-infinite-loading";
import VuePaginate from "vue-paginate";
import ScrollDiv from "vue-scroll-div";

moment.tz.setDefault("America/Guayaquil");
//Vue.use(BootstrapVueIcons)

Vue.config.productionTip = false;
// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

// Element ui
Vue.use(Element, { locale });

// InfiniteLoading
Vue.use(InfiniteLoading);

// VuePaginate
Vue.use(VuePaginate);

Vue.use(ScrollDiv);

import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";

Vue.use(PerfectScrollbar);

import VueTour from "vue-tour";
import "vue-tour/dist/vue-tour.css";

import { notifications } from "./assets/utils/notifications";
Vue.prototype.$notifications = notifications;

Vue.use(VueTour);

Vue.filter("formatBoolean", function(value) {
  return value && true;
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
