import AuthServices from "../services/AuthServices";
import BusinessService from "../../dashboard/profile/pages/business/services/index";

export default {
  state: {
    user: JSON.parse(localStorage.getItem("user")) || {}
  },

  mutations: {
    setUser: (state, user) => {
      state.user = user;
      localStorage.setItem("user", JSON.stringify(user));
    },

    setLogout: state => {
      state.user = {};
    }
  },

  actions: {
    validateEmailExist: ({ dispatch }, data) => {
      dispatch("toggleRequestLoading");
      return new Promise((resolve, reject) => {
        AuthServices.validateEmailExist(data)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          })
          .finally(() => dispatch("toggleRequestLoading"));
      });
    },
    register: ({ commit, dispatch }, data) => {
      dispatch("toggleRequestLoading");
      return new Promise((resolve, reject) => {
        AuthServices.register(data)
          .then(response => {
            if (response.data.success) {
              const { user, token, extras } = response.data;
              commit("setUser", { ...user, token, extras });

              resolve(true);
            }
            reject(false);
          })
          .catch(error => {
            reject(error);
          })
          .finally(() => dispatch("toggleRequestLoading"));
      });
    },
    login: ({ commit, dispatch }, data) => {
      dispatch("toggleRequestLoading");
      return new Promise((resolve, reject) => {
        AuthServices.login(data)
          .then(response => {
            if (response.data.success) {
              const { user, token } = response.data;
              commit("setUser", { ...user, token });
            }
            resolve(response);
          })
          .catch(error => {
            localStorage.removeItem("user");
            reject(error);
          })
          .finally(() => dispatch("toggleRequestLoading"));
      });
    },
    logout({ commit }) {
      return new Promise(resolve => {
        commit("setLogout");
        localStorage.removeItem("user");
        resolve();
      });
    },
    profileUpdate({ state, commit, dispatch }, data) {
      dispatch("toggleRequestLoading");
      return new Promise((resolve, reject) => {
        AuthServices.profileUpdate(data)
          .then(response => {
            if (response.data.success) {
              const { user } = response.data;
              commit("setUser", { ...state.user, ...user });
              resolve(response);
            }
            reject(false);
          })
          .catch(error => reject(error))
          .finally(() => dispatch("toggleRequestLoading"));
      });
    },
    userUpdate({ state, commit }, data) {
      commit("setUser", { ...state.user, ...data });
    },
    userBusinessUpdated({ dispatch, commit, getters }, user_business) {
      dispatch("toggleRequestLoading");
      return new Promise((resolve, reject) => {
        BusinessService.selectBusiness(user_business.business_ruc)
          .then(response => {
            const { data } = response;
            const { success, data: business } = data;
            if (success) {
              const user = {
                ...getters.getUser,
                ...{ user_business: business }
              };
              commit("setUser", user);
              resolve(response);
            }
            reject(false);
          })
          .catch(error => reject(error))
          .finally(() => dispatch("toggleRequestLoading"));
      });
    },
    userPlanUpdated({ commit, getters }, plan) {
      const user = {
        ...getters.getUser,
        plan
      };
      commit("setUser", user);
    },
    userConfigGeneralUpdated({ commit, getters }, userConfigGeneral) {
      const { user_config_general } = getters.getUser;
      const setUserConfigGeneral = {
        ...user_config_general,
        ...userConfigGeneral
      };

      commit("setUser", {
        ...getters.getUser,
        user_config_general: setUserConfigGeneral
      });

      AuthServices.updatedUserConfigGeneral(setUserConfigGeneral);
    }
  },

  getters: {
    getUser: ({ user }) => user,
    isLogged: ({ user }) => !!user.token,
    userInfoCompleted: ({ user }) => {
      const { email, last_name, name, phone } = user;
      if (email && last_name && name && phone) return true;
      return false;
    },
    userInfoBusinessCompleted: ({ user }) => {
      const {
        business_address,
        business_email,
        business_logo,
        business_name,
        business_phone,
        business_ruc,
        category_id,
        city_id,
        people_number
      } = user.user_business;
      if (
        business_address &&
        business_email &&
        business_logo &&
        business_name &&
        business_phone &&
        business_ruc &&
        category_id &&
        city_id &&
        people_number
      )
        return true;
      return false;
    },
    isPlanSubscriptions({ user }) {
      const { user_business } = user;
      return user_business.is_plan_subscriptions;
    }
  }
};
