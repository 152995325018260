const DashboardLayout = () => import("@/layouts/dashboard/DashboardLayout");
const DashboardHomePage = () => import("@/modules/dashboard/home/pages/Index");
const CatalogueMainComponent = () => import("../catalogue/components/Main");
const ProductListComponent = () =>
  import("../catalogue/components/products/List");
const ProductAddComponent = () =>
  import("../catalogue/components/products/Add");
const ProductEditComponent = () =>
  import("../catalogue/components/products/Edit");
const CategoryAddComponent = () =>
  import("../catalogue/components/categories/Add");
const CategoryEditComponent = () =>
  import("../catalogue/components/categories/Edit");
const CategoryListComponent = () =>
  import("../catalogue/components/categories/List");
const MovementListComponent = () =>
  import("../catalogue/components/movements/List");

//const BusinessListComponent = () => import("../business/components/List");
//const BusinessMainComponent = () => import("../business/Main");
const BusinessAddComponent = () => import("../business/components/Add");
const BusinessEditComponent = () => import("../business/components/Edit");
const PlansMainPage = () => import("../plans/pages/Main/Main");
const PlanHistoryComponent = () => import("../plans/components/PlanHistory");
const AdditionalComponent = () => import("../plans/components/Additional");
const PlanTermsComponent = () => import("../plans/components/PlanTerms");
const ConfigurationsMainComponent = () =>
  import("../configurations/components/Main");

const DashboardRouters = [
  {
    path: "/dashboard",
    component: DashboardLayout,
    redirect: "/",
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "/",
        name: "dashboard",
        component: DashboardHomePage,
        props: route => ({ query: route.query })
      },
      {
        path: "ventas",
        name: "sales",
        redirect: "ventas/documentos",
        component: () => import("../sales/pages/Main"),
        children: [
          {
            path: "documentos",
            name: "sales.documents",
            component: () => import("../sales/pages/Documents")
          },
          {
            path: "cotizaciones/nueva",
            name: "sales.quotes.add",
            component: () => import("../sales/pages/quotes/pages/QuoteAdd")
          },
          {
            path: "cotizaciones/:id/version/:idVersion",
            name: "sales.quotes.view",
            component: () => import("../sales/pages/quotes/pages/QuoteView"),
            props: route => ({ query: route.query })
          },
          {
            path: "cotizaciones/:id/version/:idVersion/editar",
            name: "sales.quotes.edit",
            component: () => import("../sales/pages/quotes/pages/QuoteEdit")
          },

          {
            path: "factura/nueva",
            name: "sales.invoices.add",
            component: () => import("../sales/pages/invoices/pages/InvoiceAdd")
          },
          {
            path: "factura/:id",
            name: "sales.invoices.view",
            component: () => import("../sales/pages/invoices/pages/InvoiceView")
          },
          {
            path: "factura/:id/editar",
            name: "sales.invoices.edit",
            component: () => import("../sales/pages/invoices/pages/InvoiceEdit")
          },

          {
            path: "liquidacion/nueva",
            name: "sales.liquidations.add",
            component: () =>
              import("../sales/pages/liquidation/pages/LiquidationAdd")
          },
          {
            path: "liquidacion/:id",
            name: "sales.liquidations.view",
            component: () =>
              import("../sales/pages/liquidation/pages/LiquidationView")
          },
          {
            path: "liquidacion/:id/editar",
            name: "sales.liquidations.edit",
            component: () =>
              import("../sales/pages/liquidation/pages/LiquidationEdit")
          },

          {
            path: "nota-credito/nueva",
            name: "sales.creditNote.add",
            component: () =>
              import("../sales/pages/credit-note/pages/CreditNoteAdd")
          },
          {
            path: "nota-credito/:id",
            name: "sales.creditNote.view",
            component: () =>
              import("../sales/pages/credit-note/pages/CreditNoteView")
          },
          {
            path: "nota-credito/:id/editar",
            name: "sales.creditNote.edit",
            component: () =>
              import("../sales/pages/credit-note/pages/CreditNoteEdit")
          },

          {
            path: "nota-debito/nueva",
            name: "sales.debitNote.add",
            component: () =>
              import("../sales/pages/debit-note/pages/DebitNoteAdd")
          },
          {
            path: "nota-debito/:id",
            name: "sales.debitNote.view",
            component: () =>
              import("../sales/pages/debit-note/pages/DebitNoteView")
          },
          {
            path: "retencion/nueva",
            name: "sales.withholdings.add",
            component: () =>
              import("../sales/pages/withholdings/pages/WithholdingAdd")
          },
          {
            path: "retencion/:id",
            name: "sales.withholdings.view",
            component: () =>
              import("../sales/pages/withholdings/pages/WithholdingView")
          },
          {
            path: "retencion/:id/editar",
            name: "sales.withholdings.edit",
            component: () =>
              import("../sales/pages/withholdings/pages/WithholdingEdit")
          },

          {
            path: "pago/:id",
            name: "sales.payments.view",
            component: () => import("../sales/pages/payments/pages/PaymentView")
          }
        ]
      },
      {
        path: "wallet",
        name: "wallet",
        component: () => import("../../sales/balance/BalanceView")
      },
      {
        path: "catalogo",
        name: "catalogue",
        redirect: "/productos",
        component: CatalogueMainComponent,
        children: [
          {
            path: "productos",
            name: "catalogue.products",
            component: ProductListComponent
          },
          {
            path: "productos/nuevo",
            name: "catalogue.products.add",
            component: ProductAddComponent
          },
          {
            path: "productos/:id/editar",
            name: "catalogue.products.edit",
            component: ProductEditComponent
          },
          {
            path: "categorias",
            name: "catalogue.categories",
            component: CategoryListComponent
          },
          {
            path: "categorias/nueva",
            name: "catalogue.categories.add",
            component: CategoryAddComponent
          },
          {
            path: "categorias/:id/editar",
            name: "catalogue.categories.edit",
            component: CategoryEditComponent
          },
          {
            path: "movimientos",
            name: "catalogue.movements",
            component: MovementListComponent
          },
          {
            path: "atributos",
            name: "catalogue.attributes",
            component: () => import("../catalogue/components/attributes/List")
          }
        ]
      },
      {
        path: "empresas",
        name: "business.main",
        component: () => import("../business/Main")
      },
      {
        path: "empresas/nueva",
        name: "business.add",
        component: BusinessAddComponent
      },
      {
        path: "empresas/:id/editar",
        name: "business.edit",
        component: BusinessEditComponent
      },
      {
        path: "planes",
        name: "plans",
        component: PlansMainPage
      },
      {
        path: "planes/historial",
        name: "plans.history",
        component: PlanHistoryComponent
      },
      {
        path: "adicionales",
        name: "plans.additionals",
        component: AdditionalComponent
      },
      {
        path: "adicionales/checkout",
        name: "plans.additionals.checkout",
        component: () => import("../plans/pages/ConfirmAdditional")
      },
      {
        path: "planes/terms",
        name: "plans.terms",
        component: PlanTermsComponent
      },
      {
        path: "planes/checkouts",
        name: "plans.checkouts",
        component: () => import("../plans/pages/Checkout")
      },
      {
        path: "planes/pp/checkouts",
        name: "plans.pp.checkouts",
        component: () => import("../plans/pages/PayphoneCheckout")
      },
      {
        path: "contratos",
        name: "agreements",
        component: () => import("../contracts/pages/ContractList")
      },
      {
        path: "contratos/:id/editar",
        name: "agreements.edit",
        component: () => import("../contracts/pages/ContractEdit")
      },
      {
        path: "contratos/plantillas",
        name: "agreements.templates",
        component: () => import("../contracts/pages/ContractTemplate")
      },
      {
        path: "perfil",
        name: "profile",
        component: () => import("../profile/pages/personal/pages/Main")
      },
      {
        path: "negocio",
        name: "profile.trade",
        component: () => import("../profile/pages/business/pages/Main")
      },
      {
        path: "configuraciones",
        name: "configurations",
        component: ConfigurationsMainComponent
      },
      {
        path: "reportes",
        name: "reports",
        component: () => import("../reports/pages/Main")
      }
    ]
  }
];

export default DashboardRouters;
