const CheckoutRouters = [
  {
    path: "/checkout",
    name: "checkout",
    component: () => import("../pages/Main"),
    children: [
      {
        path: "paylinks",
        name: "checkout.pay.links",
        component: () => import("../pages/CheckoutPayLinks")
      },
      {
        path: "confirm",
        name: "checkout.confirm.pay.links",
        component: () => import("../pages/ConfirmPayLinks")
      }
    ]
  }
];

export default CheckoutRouters;
