import Vue from "vue";
import Vuex from "vuex";

import userStore from "@/modules/auth/store/";
import plansStore from "@/modules/dashboard/plans/store";
import dashboardStore from "@/modules/dashboard/store";
import businessStore from "@/modules/dashboard/profile/pages/business/store";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    requestLoading: {
      active: false,
      text: ""
    }
  },
  mutations: {
    setRequestLoading: (state, data = {}) => {
      const { text } = data;
      state.requestLoading.text = text || "";
      state.requestLoading.active = !state.requestLoading.active;
    }
  },
  actions: {
    toggleRequestLoading: ({ commit }, data) => {
      commit("setRequestLoading", data);
    }
  },
  getters: {
    getRequestLoading: state => state.requestLoading
  },
  modules: {
    userStore,
    plansStore,
    dashboardStore,
    businessStore
  }
});
export default store;
