<template>
  <div
    id="app"
    v-loading.fullscreen="loading"
    :element-loading-text="loadingText"
    element-loading-spinner="el-icon-loading"
  >
    <TransitionFadeComponent>
      <router-view />
    </TransitionFadeComponent>
  </div>
</template>

<script>
import TransitionFadeComponent from "@/components/Transitions/Fade";

export default {
  computed: {
    loading() {
      return this.$store.getters.getRequestLoading.active;
    },
    loadingText() {
      return this.$store.getters.getRequestLoading.text;
    }
  },
  components: {
    TransitionFadeComponent
  }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700;800&display=swap");
@font-face {
  font-family: "AvertaDemoPE-ExtraBold";
  /*src: url("./assets/fonts/AvertaDemoPE-ExtraBold.eot"); /* IE9 Compat Modes */
  /*src: url("./assets/fonts/AvertaDemoPE-ExtraBold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  /*    url("./assets/fonts/AvertaDemoPE-ExtraBold.otf") format("opentype"), /* Open Type Font */
  /*    url("./assets/fonts/AvertaDemoPE-ExtraBold.svg") format("svg"), /* Legacy iOS */
  src: url("./assets/fonts/AvertaDemoPE-ExtraBold.ttf") format("truetype"); /* Safari, Android, iOS */
  /*    url("./assets/fonts/AvertaDemoPE-ExtraBold.woff") format("woff"), /* Modern Browsers */
  /*   url("./assets/fonts/AvertaDemoPE-ExtraBold.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
</style>
