import axios from "axios";
import { notifications } from "@/assets/utils/notifications";

const user = JSON.parse(localStorage.getItem("user")) || {};
const { VUE_APP_API_URL } = process.env;
//const  VUE_APP_API_URL = 'http://127.0.0.1:8000/api';

const HttpClient = axios.create({
  baseURL: VUE_APP_API_URL,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${user.token}`
  }
});

HttpClient.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const { status, data } = error.response;
    const { message } = data;

    if (status == 401) {
      // location.href = process.env.baseUrl;
    }

    notifications.error({
      message
    });
    return Promise.resolve(error);
  }
);

export default () => {
  return HttpClient;
};

export const configFormData = {
  header: {
    "Content-Type": "multipart/form-data"
  }
};
