const MainLayout = () => import("@/layouts/main/MainLayout");

const PagesRouters = [
  {
    path: "/",
    redirect: "/",
    component: MainLayout,

    children: [
      {
        path: "/",
        name: "home",
        component: () => import("../home/Home")
      },

      {
        path: "/u/:userUniqId/informacion-legal/:userBusinessid",
        name: "user.info.legal",
        component: () => import("../users/InfoLegal"),
        props: route => ({ query: route.query })
      },

      {
        path: "/ventas",
        name: "public.sales",
        component: () => import("../sales/Main"),
        children: [
          {
            path: "factura/:token",
            name: "invoice.pay",
            component: () =>
              import(
                "../../dashboard/sales/pages/payments/pages/InvoicePayPhone"
              )
          }
        ]
      }
    ]
  }
];

export default PagesRouters;
