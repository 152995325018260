import Api from "@/config/Api";

export default {
  login(data) {
    return Api().post("/login", data);
  },

  register(data) {
    return Api().post("/register", data);
  },

  profileUpdate(data) {
    return Api().post("/me", data);
  },

  passwordChange(data) {
    return Api().post("/me/password", data);
  },

  passwordRecovery(data) {
    return Api().post("/password/reset", data);
  },

  passwordRecoveryFind(token) {
    return Api().get(`/password/reset/${token}`, {});
  },

  passwordRecoveryReset(data) {
    return Api().post("/password/change", data);
  },

  validateEmailExist(data) {
    return Api().post("/register/email", data);
  },

  updatedUserConfigGeneral(data) {
    return Api().post("/me/config/general", data);
  }
};
