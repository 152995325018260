export default {
  state: {
    planTypeSelect: "month",
    planSlug: "personal-month",
    firma: false,
    plans: [
      {
        name: "Starter",
        slug: "starter-month",
        type: "month",
        description: "Plan Starter Mensual",
        price: 3,
        subTotal: 3,
        iva: 3 * 0.15,
        total: 3 * 0.15 + 3,
        automaticDebit: true,
        firma: 0
      },
      {
        name: "Starter",
        slug: "starter-yearly",
        type: "yearly",
        description: "Plan Starter Anual",
        price: 30,
        subTotal: 30,
        iva: 30 * 0.15,
        total: 30 * 0.15 + 30,
        automaticDebit: false,
        firma: 22,
        subTotalWithFirma: 52,
        ivaWithFirma: 52 * 0.15,
        totalWithFirma: 52 * 0.15 + 52
      },
      {
        name: "Basico",
        slug: "basic-month",
        type: "month",
        description: "Plan Básico Mensual",
        price: 6,
        subTotal: 6,
        iva: 6 * 0.15,
        total: 6 * 0.15 + 6,
        automaticDebit: true,
        firma: 0
      },
      {
        name: "Basico",
        slug: "basic-yearly",
        type: "yearly",
        description: "Plan Básico Anual",
        price: 60,
        subTotal: 60,
        iva: 60 * 0.15,
        total: 60 * 0.15 + 60,
        automaticDebit: false,
        firma: 22,
        subTotalWithFirma: 82,
        ivaWithFirma: 82 * 0.15,
        totalWithFirma: 82 * 0.15 + 82
      },
      {
        name: "Personal",
        slug: "personal-month",
        type: "month",
        description: "Plan Personal Mensual",
        price: 10,
        subTotal: 10,
        iva: 10 * 0.15,
        total: 10 * 0.15 + 10,
        automaticDebit: true,
        firma: 0
      },
      {
        name: "Personal",
        slug: "personal-yearly",
        type: "yearly",
        description: "Plan Personal Anual",
        price: 108,
        subTotal: 108,
        iva: 108 * 0.15,
        total: 108 * 0.15 + 108,
        automaticDebit: false,
        firma: 22,
        subTotalWithFirma: 130,
        ivaWithFirma: 130 * 0.15,
        totalWithFirma: 130 * 0.15 + 130
      },
      {
        name: "Empresa",
        slug: "business-month",
        type: "month",
        description: "Plan Empresa Mensual",
        price: 30,
        subTotal: 30,
        iva: 30 * 0.15,
        total: 30 * 0.15 + 30,
        automaticDebit: true,
        firma: 22
      },
      {
        name: "Empresa",
        slug: "business-yearly",
        type: "yearly",
        description: "Plan Empresa Anual",
        price: 324,
        subTotal: 324,
        iva: 324 * 0.15,
        total: 324 * 0.15 + 324,
        automaticDebit: false,
        firma: 22,
        subTotalWithFirma: 346,
        ivaWithFirma: 346 * 0.15,
        totalWithFirma: 346 * 0.15 + 346
      },
      {
        name: "Firma Electrónica",
        slug: "signature",
        type: "yearly",
        description: "Firma Electrónica Anual",
        price: 45,
        subTotal: 45,
        iva: 45 * 0.15,
        total: 45 * 0.15 + 45,
        automaticDebit: false,
        firma: 22
      },
      {
        name: "Emprendedor",
        slug: "emprendedor-yearly",
        type: "yearly",
        description: "Plan Emprendedor Anual",
        price: 15,
        subTotal: 15,
        iva: 15 * 0.15,
        total: 15 * 0.15 + 15,
        automaticDebit: false,
        firma: 22,
        subTotalWithFirma: 37,
        ivaWithFirma: 37 * 0.15,
        totalWithFirma: 37 * 0.15 + 37
      }
    ]
  },
  mutations: {
    setPlanTypeSelect: (state, planTypeSelect) => {
      state.planTypeSelect = planTypeSelect;
    },
    setPlanSlug: (state, planSlug) => {
      state.planSlug = planSlug;
    },
    setFirma: (state, firma) => {
      state.firma = firma;
    }
  },
  actions: {
    setPlanTypeSelect: ({ commit }, planTypeSelect) => {
      commit("setPlanTypeSelect", planTypeSelect);
    },
    setPlanSlug: ({ commit }, planSlug) => {
      commit("setPlanSlug", planSlug);
    },
    setFirma: ({ commit }, firma) => {
      commit("setFirma", firma);
    }
  },
  getters: {
    getPlans: ({ plans }) => {
      return plans;
    },
    getPlanCurrent: ({ plans, planSlug }) => {
      return plans.find(plan => plan.slug === planSlug);
    },
    getPlanTypeSelect: ({ planTypeSelect }) => planTypeSelect,
    getPlanSlugSelect: ({ planSlug }) => planSlug,
    getFirma: ({ firma }) => firma
  }
};
