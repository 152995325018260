import Api from "@/config/Api";

export default {
  createBusiness(data) {
    return Api().post("/business", data);
  },

  deleteBusiness(businessId) {
    return Api().delete(`/business/${businessId}`);
  },

  selectBusiness(businessRuc) {
    return Api().post(`/business/selected`, { ruc: businessRuc });
  },

  getInfoBusinessCompleted() {
    return Api().get(`/business/utils/info-completed`);
  },

  getBankAccounts() {
    return Api().get(`/business/utils/bank-accounts`);
  },

  addBankAccount(data) {
    return Api().post(`/business/utils/bank-accounts`, data);
  },
  deleteBankAccount(bankAccountId) {
    return Api().delete(`/business/utils/bank-accounts/${bankAccountId}`);
  },

  updateBankAccount(data) {
    return Api().put(`/business/utils/bank-accounts/${data.id}`, data);
  },

  getBusinessEarnings(options = {}) {
    return Api().get(`/business/utils/earnings`, options);
  },

  getBusinessWithdraws(options = {}) {
    return Api().get(`/business/utils/withdraws`, options);
  },
  addBusinessWithdraws(data) {
    return Api().post(`/business/utils/withdraws`, data);
  },

  getBusinessTransactions(options = {}) {
    return Api().get(`/business/utils/transactions`, options);
  },
  getBusinessPayForms(options = {}) {
    return Api().get(`/business/config/pay-forms`, options);
  },
  addBusinessPayForms(options = {}) {
    return Api().post(`/business/config/pay-forms`, options);
  },

  // Reports

  getReportsTotalBalance(options = {}) {
    return Api().get(`/business/reports/total-balance`, options);
  },

  getReportsTotalWithdraws(options = {}) {
    return Api().get(`/business/reports/total-withdraws`, options);
  },

  getReportsTotalEarnings(options = {}) {
    return Api().get(`/business/reports/total-earnings`, options);
  }
};
