let LoginPage = () => import("../pages/Login/Login");
let RegisterPage = () => import("../pages/Register/Register");
let RecoveryPasswordPage = () =>
  import("../pages/RecoveryPassword/RecoveryPassword");

let NewPasswordPage = () => import("../pages/NewPassword/NewPassword");

const AuthRouters = [
  {
    path: "/login",
    name: "login",
    component: LoginPage,
    props: route => ({ query: route.query })
  },

  {
    path: "/register",
    name: "register",
    component: RegisterPage
  },

  {
    path: "/recovery-password",
    name: "recovery-password",
    component: RecoveryPasswordPage
  },

  {
    path: "/new-password/:token",
    name: "new-password",
    component: NewPasswordPage,
    props: route => ({ query: route.query })
  }
];

export default AuthRouters;
